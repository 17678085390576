import React from 'react';
import cn from '../../../../lib/classNames';
import Accordion from '../Accordion';
import TabInfo from './TabInfo';

import styles from './SafestPlaces.module.scss';

export default function SafestPlaces(props) {
	const { data, helpMessage } = props;
	const showHelpMessage = !data.length;
	return (
		<div className={styles['safest-places']}>
			{showHelpMessage ? (
				helpMessage
			) : (
				<>
					<p className={cn(styles.description, styles['header-description'])}>
						Areas that are generally considered safe, relative to the area, and
						have lower reported incidents of crime:
					</p>
					<TabInfo
						className={styles['contacts-wrap']}
						data={data}
						icon='encrypted'
					/>
					<p className={styles.description}>
						Remember, even in safe areas, it's important to be aware of your
						surroundings at all times, especially after dark and if alone.
						Always keep your belongings secure and avoid displaying expensive
						items such as jewelry or electronics.
					</p>
				</>
			)}
		</div>
	);
}

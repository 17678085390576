import React from 'react';
import StatisticsDistributionViCRI from '../StatisticsDistributionViCRI/StatisticsDistributionViCRI';
import Tabs from '../../../../components/ui/Tabs/Tabs';

// import { ReactComponent as DistributionImage } from './images/distribution.svg';
// import { ReactComponent as MapImage } from './images/map.svg';
import StatisticsMapViCRI from './StatisticsMapViCRI';

import styles from './NationalViCRIBlock.module.scss';

export default function NationalViCRIBlock(props) {
	const {
		countries,
		crimeIndexGeography,
		home,
		destination,
		destinationCoordinates,
		distributions,
		isLimitedVersion = false,
		homeCode,
		destinationCode,
	} = props;

	const existDistributionData =
		distributions && distributions.HR && distributions.HR.distribution.length;

	const showTabs = isLimitedVersion || existDistributionData;
	const tabs = [
		{
			label: 'Statistics',
			id: 'distribution',
			// icon: <DistributionImage />,
			content: (
				<StatisticsDistributionViCRI
					distributionLevel='country'
					distributions={distributions}
					homeCode={homeCode}
					destinationCode={destinationCode}
					isDemo={isLimitedVersion}
					showLabels
				/>
			),
		},
		{
			label: 'World map',
			id: 'world-map',
			// icon: <MapImage />,
			content: (
				<StatisticsMapViCRI
					countries={countries}
					crimeIndexGeography={crimeIndexGeography}
					home={home}
					destination={destination}
					destinationCoordinates={destinationCoordinates}
				/>
			),
		},
	];

	return (
		<div className={styles['statistics-container-vicri']}>
			{showTabs && <Tabs tabs={tabs} initialTab='distribution' />}
		</div>
	);
}

import moment from 'moment';

import Config from '../config';

const debounce = (func, wait, immediate) => {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export { debounce };

export { default as localStore } from './localStore';

export const log = (...props) => {
	if (Config.log) console.log(...props);
};

export const randomString = () => Math.random().toString(36).substring(7);

export const truncate = (str, n) => {
	return str.length > n ? str.substr(0, n - 1) + '...' : str;
};

export const roundNumber = (v, l = 2) => {
	try {
		const value = Math.round(parseFloat(v) * Math.pow(10, l)) / Math.pow(10, l);
		if (isNaN(value)) {
			return v;
		}
		return value;
	} catch {
		return v;
	}
};

export const getRoundingByCondition = (value) => {
	if (value || value === 0) {
		if (value === 0) {
			return value;
		} else {
			const valueAbs = Math.round(Math.abs(value));

			if (valueAbs > 0 && valueAbs < 8) {
				return value < 0 ? -5 : 5;
			} else if (valueAbs.toString().length >= 3) {
				return Math.round(value / 10) * 10;
			} else {
				return Math.round(value / 5) * 5;
			}
		}
	}

	return;
};

export const formaterDate = (date, format = 'YYYYMMDD') => {
	return moment(date).format(format);
};

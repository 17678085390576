import React from 'react';
import cn from '../../../../lib/classNames';
import TabInfo from './TabInfo';

import styles from './Hospitals.module.scss';

export default function Hospitals(props) {
	const { data } = props;
	return (
		<div className={styles.hospitals}>
			<p className={cn(styles.description)}>
				Recommended hospitals that are more likely to offer 24-hour
				emergency/critical care and English-speaking staff. It is strongly
				advised to confirm these options prior to or immediately upon arrival
				with your local hosts or embassy:
			</p>
			<TabInfo
				className={styles['contacts-wrap']}
				data={data}
				icon='emergency'
			/>
			<p className={styles.description}>
				It's always a good idea to have travel insurance that covers medical
				expenses and to notify your embassy if you need serious medical
				attention. Please note that while these hospitals offer a high standard
				of care, the language barrier can be a challenge. It might be beneficial
				to have a translation app or service readily available.
			</p>
		</div>
	);
}

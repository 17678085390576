import React from 'react';

import qrcode from 'qrcode-generator';

import styles from './TravelHealthNoticesLevel.module.scss';
import { formaterDate } from '../../../../lib';
import cn from '../../../../lib/classNames';
import LinkBox from '../../RiskBriefReport/LinkBox';

const HEADER_TEXTS = {
	3: 'Warning Level 3, Avoid Nonessential Travel',
	2: 'Alert Level 2, Practice Enhanced Precautions',
	1: 'Watch Level 1, Practice Enhanced Precautions',
};

export default function TravelHealthNoticesLevel(props) {
	const { levelNum, levelValue } = props;

	const renderLevelItems = levelValue.map((e) => {
		const { title, description, datePublished, sourceUrl } = e;
		const date = datePublished && formaterDate(datePublished, 'MMM DD, YYYY');
		const qr = qrcode(0, 'M');
		qr.addData(sourceUrl);
		qr.make();

		return (
			<div className={styles['notice-content']} key={title}>
				<div className={styles['notice-item']}>
					<div className={cn(styles['top-block'])}>
						<div>
							<h4 className={styles.title}>{title}</h4>
							<p className={styles.date}>{date}</p>
							<p className={styles.description}>{description}</p>
						</div>
						<div className={styles.qrcode}>
							<img src={qr.createDataURL()} alt='QRC' />
						</div>
					</div>
					<div className={styles['bottom-block']}>
						<div className={styles.url}>
							<LinkBox href={sourceUrl} iconBefore='link'>
								{sourceUrl}
							</LinkBox>
						</div>
					</div>
				</div>
			</div>
		);
	});

	return (
		<div
			key={`level${levelNum}`}
			className={cn(
				styles['health-notice'],
				styles[`health-notice-level-${levelNum}`]
			)}
		>
			<div className={styles['notice-header']}>
				<span className='material-icons-round'>warning_amber</span>
				<span className={styles['notice-header-text']}>
					{HEADER_TEXTS[levelNum]}
				</span>
			</div>
			{renderLevelItems}
		</div>
	);
}

import React from 'react';

import cn from '../../../../lib/classNames';
import Hospitals from './Hospitals';
import SafestPlaces from './SafestPlaces';
import HighRisk from './HighRisk';
import Tabs from '../../../../components/ui/Tabs/Tabs';
import styles from './Locations.module.scss';

export default function Locations(props) {
	const { className, data } = props;

	const tabData = [
		{
			label: 'High Risk Areas',
			id: 'high-risk-areas',
			icon: (
				<span className="material-symbols-rounded color-dangerous">
					dangerous
				</span>
			),
			content: (
				<HighRisk
					data={data.areas.high_risk_locations}
					helpMessage={data.areas.help_message_risk}
				/>
			),
		},
		{
			label: 'Safest Places',
			id: 'safest-places',
			icon: (
				<span className="material-symbols-rounded color-encrypted">
					encrypted
				</span>
			),
			content: (
				<SafestPlaces
					data={data.areas.safest_areas}
					helpMessage={
						!data.areas.safest_areas.length
							? data.areas.help_message_safest
							: ''
					}
				/>
			),
		},
		{
			label: 'Hospitals',
			id: 'hospitals',
			icon: (
				<span className="material-symbols-rounded color-emergency">
					emergency
				</span>
			),
			content: <Hospitals data={data.hospitals} />,
		},
	];

	return (
		<section className={cn(styles.locations, className)}>
			<Tabs tabs={tabData} initialTab="high-risk-areas" />
		</section>
	);
}

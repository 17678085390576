import React from 'react';
import cn from '../../../../lib/classNames';
import TabInfo from './TabInfo';

import styles from './HighRisk.module.scss';

export default function HighRisk(props) {
	const { data, helpMessage } = props;
	const showHelpMessage = !data.length;

	return (
		<div className={styles['high-risk']}>
			{showHelpMessage ? (
				helpMessage
			) : (
				<>
					<p className={cn(styles.description, styles['header-description'])}>
						Areas that are known to be of higher risk and may require additional
						caution:
					</p>
					<TabInfo
						className={styles['contacts-wrap']}
						data={data}
						icon='dangerous'
					/>
				</>
			)}
		</div>
	);
}

import React, { useState } from 'react';
import Config from '../../../../config';
import { Map } from '../../../Covid19';
import cn from '../../../../lib/classNames';
import TabButton from '../TabButton';
import { MAP_GROUP } from '../RiskBriefReport';

import styles from './LocationMap.module.scss';

export default function LocationMap(props) {
	const { className, data, locationCenter } = props;

	const [tab, setTab] = useState(MAP_GROUP[0]);

	const renderTabButton = MAP_GROUP.map((e) => {
		const list = e.getList(data);
		if (!list.length && e.field !== 'all') return null;
		return (
			<TabButton
				key={e.btnText}
				className={cn(styles['tab-button'], `btn-${e.field}`)}
				iconBefore={
					e.icon && (
						<span
							className={`material-symbols-rounded btn-icon icon-${e.icon}`}
						>
							{e.icon}
						</span>
					)
				}
				onClick={() => setTab(e)}
				active={tab === e}
			>
				{e.btnText}
			</TabButton>
		);
	});

	const markersData = () => {
		if (tab.field === 'all') {
			const mergedArray = [];

			MAP_GROUP.forEach((tab) => {
				if (tab.getList) {
					const screenArray = tab.getList(data);
					if (screenArray) {
						mergedArray.push(...screenArray);
					}
				}
			});

			return mergedArray;
		}

		return tab.getList(data);
	};

	return (
		<section className={cn(styles['location-map'], className)}>
			<header className={styles.header}>
				<div className={styles['btn-wrap']}>{renderTabButton}</div>
			</header>
			<Map
				className={styles['map-container']}
				accessToken={Config.mapbox.accessTokenReport}
				mapStyle={Config.mapbox.styleReport}
				center={locationCenter}
				// marker={locationCenter}
				zoom={10}
				style={null}
				markers={markersData()}
			/>
		</section>
	);
}

import React from 'react';
import { fileFlagUrl } from '../../../lib/countries';
import LinkBox from '../RiskBriefReport/LinkBox';
import cn from '../../../lib/classNames';

import styles from './TravelAdvice.module.scss';

export default function TravelAdvice(props) {
	const { travelSupportInfo } = props;

	const countryCode = travelSupportInfo?.code2;
	const countryName = travelSupportInfo?.name;

	const links = travelSupportInfo?.links || {};

	const flagUrl = countryCode ? fileFlagUrl(countryCode.toLowerCase()) : null;

	const renderTravelAdvice = !countryCode ? null : (
		<div className={styles['travel-advice']}>
			<div className={styles.header}>
				<div>
					<h2 className={styles.title}>
						{countryName} Travel Advice from Key Sources
					</h2>
					<p className={styles['sub-title']}>
						Crime, safety, health, and other critical information for
						international travelers from selected government sources
					</p>
				</div>
				{!!flagUrl && (
					<div>
						<div className={styles['travel-advice-flag']}>
							<img
								className={styles['flag-icon']}
								src={flagUrl}
								alt={countryCode}
							/>
						</div>
					</div>
				)}
			</div>
			<div className={cn(styles.body, 'section-wrap-white')}>
				<div className={styles['btn-wrap']}>
					{Object.keys(links).map((key) => (
						<LinkBox
							key={key}
							className='flex-auto'
							href={links[key]}
							variant='filled'
							iconBefore='link'
						>
							{key}
						</LinkBox>
					))}
				</div>
			</div>
		</div>
	);

	return renderTravelAdvice;
}

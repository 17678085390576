import React from 'react';
import { ViolentCrimeRisk } from '../../../Covid19';
import StatisticsDistributionViCRI from '../StatisticsDistributionViCRI/StatisticsDistributionViCRI';
import Tabs from '../../../../components/ui/Tabs/Tabs';

// import { ReactComponent as DistributionImage } from './images/distribution.svg';
// import { ReactComponent as Speedometer } from './images/speedometer.svg';

import styles from './CitiesViCRIBlock.module.scss';

export default function CitiesViCRIBlock(props) {
	const {
		distributions,
		isLimitedVersion = false,
		homeCode,
		destinationCode,
		locationHome,
		locationDestination,
		riskValueHome,
		riskValueDestination,
		locationCircumstancesHome,
		locationCircumstancesDestination,
		homeCrimeRiskIndexLocations,
		destinationCrimeRiskIndexLocations,
	} = props;

	const existDistributionData =
		distributions && distributions.HR && distributions.HR.distribution.length;

	const showTabs = isLimitedVersion || existDistributionData;

	const tabs = [
		{
			label: 'Rating',
			id: 'rating',
			// icon: <Speedometer />,
			content: (
				<ViolentCrimeRisk
					showHeader={false}
					title='The Violent Crime Risk Index for Global Cities'
					subTitle='Your home and destination ranking of homicide, robbery, rape, and aggravated assault risks.'
					showTooltipIcon
					showDownloadScreenIcon={false}
					locationHome={locationHome}
					locationDestination={locationDestination}
					riskValueHome={riskValueHome}
					riskValueDestination={riskValueDestination}
					homeCrimeRiskIndexLocations={homeCrimeRiskIndexLocations}
					destinationCrimeRiskIndexLocations={
						destinationCrimeRiskIndexLocations
					}
					locationCircumstancesHome={locationCircumstancesHome}
					locationCircumstancesDestination={locationCircumstancesDestination}
					isShowEstimate={false}
				/>
			),
		},
		{
			label: 'Statistics',
			id: 'distribution',
			// icon: <DistributionImage />,
			content: (
				<StatisticsDistributionViCRI
					distributionLevel='city'
					distributions={distributions}
					homeCode={homeCode}
					destinationCode={destinationCode}
					isDemo={isLimitedVersion}
					showLabels
				/>
			),
		},
	];

	return (
		<div className={styles['statistics-container-vicri']}>
			{showTabs && <Tabs tabs={tabs} initialTab='rating' />}
		</div>
	);
}
